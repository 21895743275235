import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CloudApiIntegration from "../components/integration/CloudApiIntegration";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import { chatGPT } from "../labels/labels.js";

import { Toast } from "primereact/toast";

const ChatGPTActivation = (props) => {
  const [token, setToken] = useState("API token");
  const [message, setMessage] = useState(
    "Se quiser saber sobre outros assuntos basta perguntar usando texto ou áudio, pois estou aqui para ajudar."
  );
  const [agentId, setAgentId] = useState("AgentId");
  const botId = props.botId;
  const cloudApiIntegration = new CloudApiIntegration();
  const toast = useRef(null);
  useEffect(() => {
    cloudApiIntegration
      .getChatGPT(botId)
      .then((response) => {
        if (response.message) setMessage(response.message);
        if (response.token) setToken(response.token);
        if (response.agentId) setAgentId(response.agentId);
      })
      .catch((error) => {
        console.log("🚀 error:", error);
        toast.current.show({
          severity: "error",
          summary: chatGPT.error,
          detail: chatGPT.errorText,
          life: 5000,
        });
      });
  }, []);

  const showResponseMessage = (json) => {
    if (json.id) {
      props.chatGPTSucceed();
    } else {
      toast.current.show({
        severity: "error",
        summary: chatGPT.error,
        detail: cloudActchatGPTvation.errorText,
        life: 5000,
      });
    }
  };

  const onCancel = () => {
    props.handleCloseChatGPT();
  };

  const onSubmit = () => {
    cloudApiIntegration
      .postChatGPT(botId, token, message, agentId)
      .then((response) => {
        showResponseMessage(response);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: chatGPT.error,
          detail: chatGPT.errorText,
          life: 5000,
        });
      });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Typography variant="h4" component="h4" style={{ marginBottom: "20px" }}>
        ChatGPT API
      </Typography>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12} md={12} marginTop="10px">
          <span className="p-float-label">
            <InputText
              id="token"
              type="text"
              name="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              style={{ width: "100%" }}
            />
            <label htmlFor="token">ChatGPT API Key:</label>
          </span>
        </Grid>
        <Grid item xs={12} md={12} marginTop="10px">
          <span className="p-float-label">
            <InputText
              id="agentId"
              type="text"
              name="agentId"
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
              style={{ width: "100%" }}
            />
            <label htmlFor="agentId">AgentId:</label>
          </span>
        </Grid>
        <Grid item xs={12} md={12} marginTop="10px">
          <span className="p-float-label">
            <InputTextarea
              rows={4}
              id="message"
              type="text"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ width: "100%" }}
              autoResize
            />
            <label htmlFor="message">Mensagem depois da integração:</label>
          </span>
        </Grid>

        <Grid item xs={12} justifyContent="flex-end" display="flex">
          <Button
            type="submit"
            onClick={onSubmit}
            icon="pi pi-save"
            label={chatGPT.btnSave}
            className="p-button-text p-m-1"
          ></Button>
          <Button
            onClick={onCancel}
            icon="pi pi-times"
            label={chatGPT.btnCancel}
            className="p-button-text p-m-1"
          ></Button>
        </Grid>
      </Grid>
    </div>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ChatGPTActivation;
